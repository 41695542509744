
















// import { MerchantModule } from '@/store/modules/Merchant';
import { Vue, Component } from 'vue-property-decorator';
@Component({
  name: 'TabRouterView'
})
export default class extends Vue {
  tabbarList = [
    {
      url: { name: 'Index' },
      // url: `/${MerchantModule.merchantIdAppId}/index`,
      title: '首页',
      activeIcon: '//means-static.luckyop.com/images/20210929/1fgo3hh5lbuvv1keae9g.png',
      inactiveIcon: '//means-static.luckyop.com/images/20210929/1fgo3ae72c6juunmbpdo.png'
    },
    {
      url: { name: 'Category' },
      // url: `/${MerchantModule.merchantIdAppId}/category`,
      title: '分类',
      activeIcon: '//means-static.luckyop.com/images/20210929/1fgo4v6g2t6ir3t3i0vg.png',
      inactiveIcon: '//means-static.luckyop.com/images/20210929/1fgo3b1o8m3e355m46co.png'
    },
    {
      url: { name: 'ShoppingCar' },
      // url: `/${MerchantModule.merchantIdAppId}/shopping-car`,
      title: '购物车',
      activeIcon: '//means-static.luckyop.com/images/20210929/1fgo50330j1jl4n7qi28.png',
      inactiveIcon: '//means-static.luckyop.com/images/20210929/1fgo3ccdbfdspil04b08.png'
    },
    {
      url: { name: 'Mine' },
      // url: `/${MerchantModule.merchantIdAppId}/mine`,
      title: '我的',
      activeIcon: '//means-static.luckyop.com/images/20210929/1fgo50oc6obvi2pjrug8.png',
      inactiveIcon: '//means-static.luckyop.com/images/20210929/1fgo3d2189ghkm3od18.png'
    }
  ];
}
